import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../../../../components/structure/layout'

import storyCoverImage from '../../../../images/athene-d2-character-story.png'

const AtheneD2CharacterStoryPage = () => (
  <Layout>
    <Helmet title="Athene - D2 Character Story" />
    <section>
      <h1>Athene - D2 Character Story</h1>
      <div className="disclaimer-container">
        <span className="year">~ year: 2001 | Georgi's age: 16</span>
        <span className="disclaimer">
          Unedited things the way they were in the late 90s and early 2000s
        </span>
      </div>

      <div>
        <img src={storyCoverImage} alt="Athene story cover" />
      </div>

      <div>
        <pre>
          Her parents were the magicion Zeus from Diablo I and the sorceress
          Athine-Palade from Diablo II LOD. These two magi-being people had
          their own two daughters named Athine-Palade and Athine-Palade II.
          After some years passed Athine-Palade II left their homeland and went
          to the east, experiencing her life in LOD mysteries. Athine-Palade
          (the other daughter) lived with her family for many many years, while
          one day suddenly from the sky fell fire! All people were anihilaited
          and burnt down to the ground, leaving only ashes as the last proof of
          their once glorious existance. Only Athine (the daughter) remained
          just like she was. She hadn't even one scratch. The god of hellfire
          left her only because he knew that he will not be able to kill her,
          even with all the dark powers surrounding him. He had the knowledge of
          Tyriel. The angel of light always had the protection of someone. First
          it was Zeus that the angel was protecting. Then Tyriel was protecting
          the LOD sorci Athine-Palade (the mother). Tyriel didn't want this to
          happen but orders came from above so that he had to push on and leave
          these two family mages, with whom he had shared many many years. He
          was told to go find the new born sister of this magi family, named --
          Athine-Palade II. She was LOD experienced character so it was easy for
          Tyriel to find her. Three months later he found her at the battle-net
          area fighting some really good other heroes for the holy armor, known
          by mortal men as Arcane's Valor v1.08. She had heard of her father
          that he once had that armor, but during one glorious battle he was
          pushed to flee, so he left this powerful item. After watching how the
          young 76 lvl sorci slew these two paladins and one amazon, Tyriel left
          his hiding place and revealed himself to Athine-Palade II. He gave her
          the name of Matriarch of the whole mortal realm and drove her to the
          most distant heaven inpost. These were places of high rating, which
          only the most experienced and noble characters can go to. After
          leaving Athine-Palade II there, Tyriel was given orders to go back to
          her parents and protect the new born daughter Athine-Palade. Yes,
          these two old friends had their new daughter named after her mother.
          Tyriel was told to hurry, though. Dark powers were sensed to temporary
          surround the homeland of the magi-family. It was Tyriel who had to
          hurry up, becuse of the orders above, so he decided to sacrifice his
          own life and title for this holy operation. If he had gone the normal
          way back through all the towns and areas, he would had missed the
          attack over the mortal realm. He went through the undead realm, which
          he was told not to go through. Not even one angel should ever visit
          this place, which is known by mortal men as Chaos Sanctuary. Diablo
          used this place many years ago to rise his undead minions and send
          them to terrorise Heaven's last outpost before the shadows of Hell.
          This was the same outpost, named Pandemonium Fortress, that all the
          heroes who were brave enough to face Diablo had came to. Not even one
          of them, though, came back or slew Diablo. Even for Athine-Palade II,
          and for Her mother was difficult to do so, so they cleaverly and
          wisely skipped this part of their march against evil! So Tyriel had to
          go through this place, only by himself. The last step he had always
          moved in here was just before the door out from the Pandemonium
          Fortress. He had his fears, but none of them happened. It appeared
          that there was nobody inside the Chaos Sanctuary. That was no good,
          though he was happy that no one can stop him here. The secret thought
          of Tyriel already knew what was going to happed. Diablo and his
          minions were just about to attack the magi family. That's why orders
          from above told Tyriel to hurry up to protect once again the magi
          family against the dark powers of the undead realm. Terror,
          Destruction and Hatred should not come loose upon this mortal world.
          The other part of the adventure back was nothing but a secure and long
          journey in time. During all this time Athine-Palade, the "new born"
          sister, was already 17 years old, so she was just ready for the
          magicks and ancients secrets exam of the older sorceresses, mages,
          magis and all who pretend to use magic as their weapon for defending
          the Light and the mortal realm. Humans have always thought they were
          the best, they can do anything. Tyriel knew they can't! He was just
          approaching their possition, while the sky rained fire, all the rivers
          covered with human blood, the water was Hatred... The sky was
          Destruction... The wind drifting shadows of dead long ago noble heroes
          was TERROR... Mephisto, Diablo and Baal came among us once again.
          Tyriel could not believe his eyes. How could this happen. There were
          so many fearless heroes that fought against the power of darkness.
          Damn, I told the command above that Diablo won't waste time. He was
          preparing this powerful blow over the mortals all these years. Now I
          remember, why I was told not to mess with Diablo while he first attack
          the peaceful village of Tristram. Yep, Diablo was not in there at that
          moment. He created dangerous and huge monster of the souls he had
          killed. This monster was slain. With it the first step of conquering
          the humanity was made. The hero that slew it was called Kilain - a
          rogue from the near Rogue Encampment. She then gave birth to a kid,
          which she named Kilain, after her. Three day after, the skys darkened
          announcing that the soul of Kilain was now one of Diablo's most
          dangerous weapons. The small town of Tristram was almost burnt to
          ashes, Griswold and Wirt were killed, only one old man, named Decard
          Cain, survived. I drove him to Heaven's inpost for great heroes. He is
          sound and healthy even now... Many heroes tried to kill Diablo (the
          old brave Kilain). She mutated, though and everybody forgot about what
          she had done for Tristram. Her daughter Kilain found "Diablo-Kilain"
          and killed him. Once again the humans celebrated, while I knew that
          was not Diablo's soul again. Untill now many heroes tried to find the
          other two brothers, but nobody did so. Now I guess the THREE brothers
          found what they were searching for. When all people were killed
          without Tyriel to be able to do something, Athine-Palade was still
          alive. She couldn't believe her eyes. All her friends were dead. The
          sense of revenge began to torture deep inside her. Tyriel showed up.
          He told Athine-Palade about what was happening all these years, what
          he had remembered of. Should you Athine follow the Light and go after
          Diablo, Mephisto and Baal once again, sacrificing your life, or may be
          should you stand against the Light above and ask them why did they let
          Diablo do his show all these years. Why so many noble heroes and brave
          townsman had to die before Diablo. Was this script for the humanity,
          which it could not change. The people wanted peace everywhere. They
          were tired of all the bloody wars of terror, the hatred between people
          and the destruction of the terror and hatred. It is up to you now what
          to do. Choose your destiny!
        </pre>
      </div>

      <Link to="/">Go back to the homepage</Link>
    </section>
  </Layout>
)

export default AtheneD2CharacterStoryPage
